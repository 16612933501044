// i18next-extract-mark-ns-start paypal

//Payment methods
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
//Images
import paypal_monei from 'images/paypal_monei.svg';

// Clients
import React from 'react';
import styled from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {IndexBackground} from 'components/landings/IndexBackground';
import {MainTitleImg} from 'components/payment-methods/MainTitleImg';
import {MainTitleContent} from 'components/payment-methods/MainTitleContent';
import {InternalPageLink} from 'components/links/Pages';
import {Card, CardImage, CardWrapper} from 'components/Card';
import site_template from 'images/site_template.svg';
import coinstack from 'images/coinstack.svg';
import cart from 'images/cart.svg';
import click from 'images/click1.svg';
import mobile_payment from 'images/mobile_payment.svg';
import person from 'images/person.svg';
import CardsContainer from 'components/CardsContainer';
import Bold from 'components/Bold';
import {List} from 'components/List';
import {PrismicPage} from 'components/links/PrismicPage';
import {AndroidDownloadLink} from 'components/DownloadOnGooglePlay';
import {IosDownloadLink} from 'components/DownloadOnAppleStore';
import {Partners} from 'components/Partners';
import moneiPayFormEs from 'images/monei_pay_form_es.png';
import moneiPayFormEn from 'images/monei_pay_form_en.png';
import {FaqsSection} from 'components/FaqsSection';
import {BlogPost} from '../../../types/BlogTypes';
import {BlogCategoryType} from '../../../types/BlogTypes';
import {getPostsFromPrismicEdges} from 'utils/blog-utils';
import {mapCategory} from 'utils/blog-utils';
import {RelatedBlogPosts} from 'components/RelatedBlogPosts';

const Paypal: React.FC = (props: any) => {
  const {t, language} = useI18next();
  const moneiPayForm = ['es', 'ca'].includes(language) ? moneiPayFormEs : moneiPayFormEn;

  const {data} = props;
  const categories: BlogCategoryType[] = data.prismic.allCategorys.edges.map(mapCategory) || [];
  const popularPosts: BlogPost[] = getPostsFromPrismicEdges({
    posts: data.popularPosts.allPosts.edges,
    categories
  });

  const faqs = [
    {
      header: t('How do I add PayPal to my MONEI account?'),
      text: t(
        'You can start accepting PayPal payments on the Web using Hosted Payment Page or PayPal Component. No additional configuration is required to use PayPal in Hosted Payment Page.'
      ),
      content: (
        <Trans>
          You can start accepting PayPal payments on the Web using{' '}
          <AnchorLink href="https://docs.monei.com/docs/integrations/use-prebuilt-payment-page/">
            Hosted Payment Page
          </AnchorLink>{' '}
          or{' '}
          <AnchorLink href="https://docs.monei.com/docs/monei-js/reference/#paypal-component">
            Paypal component.
          </AnchorLink>{' '}
          No additional configuration is required to use PayPal in Hosted Payment Page.
        </Trans>
      )
    },
    {
      header: t('What are the transaction fees for PayPal payments?'),
      text: t(
        'PayPal has its own fixed costs and per-transaction fees for merchants. MONEI fees for PayPal payments are always -0.10% of your dynamic credit card gross volume fee. For example, if your credit card gross volume fee is 0.40% your PayPal gross volume fee will be 0.30%.'
      ),
      content: (
        <Trans>
          PayPal has its own fixed costs and per-transaction fees for merchants. MONEI fees for
          PayPal payments are always -0.10% of your dynamic credit card gross volume fee. For
          example, if your credit card gross volume fee is 0.40% your PayPal gross volume fee will
          be 0.30%.
        </Trans>
      )
    },
    {
      header: t('How are PayPal transactions paid out to me?'),
      text: t(
        'The payout of PayPal transactions is slightly different than other payment methods. When you receive a PayPal transaction, you will be paid out by PayPal itself and not by MONEI. You can also adjust your PayPal payout frequency in your PayPal account.'
      ),
      content: (
        <Trans>
          The payout of PayPal transactions is slightly different than other payment methods. When
          you receive a PayPal transaction, you will be paid out by PayPal itself and not by MONEI.
          You can also adjust your PayPal payout frequency in your PayPal account.
        </Trans>
      )
    },
    {
      header: t('For how long can I refund PayPal transactions?'),
      text: t('You can refund PayPal transaction up to 180 days after the day of the payment.'),
      content: (
        <Trans>
          You can refund PayPal transaction up to 180 days after the day of the payment.
        </Trans>
      )
    }
  ];

  return (
    <>
      <SEO
        title={t('Accept PayPal Payments Online & In-Person')}
        description={t(
          'Increase sales and customer satisfaction by accepting PayPal online, in-store, at your restaurant, or anywhere your business takes you. Start here ››'
        )}
      />
      <IndexBackground
        sx={{
          left: {all: '108%', xl: '119%', lg: '1325px'},
          top: {all: '-100%', xl: '-108%', lg: '-125%'},
          height: {all: '2150px', lg: '1960px'},
          width: {all: '1530px'},
          transform: {
            all: 'rotate(-58deg) skew(0deg, 17deg)',
            xl: 'rotate(-58deg) skew(2deg,33deg)',
            lg: 'rotate(-58deg) skew(12deg,12deg)'
          }
        }}>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent>
              <SectionHeader underline tagName="h1">
                <Trans>Accept PayPal payments</Trans>
              </SectionHeader>
              <Trans parent="p">
                With 429+ million active users, PayPal is the most popular digital wallet among
                consumers globally, and the most used payment method after credit and debit cards.
                Accept PayPal to increase sales and customer satisfaction — integrate with MONEI to
                let customers securely pay online or in person with their stored payment information
                or PayPal balance.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg
              src={paypal_monei}
              alt="MONEI payment gateway"
              title="MONEI payment gateway"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Content style={{marginTop: '40px'}}>
        <Section centered textAlign="center" column>
          <SectionHeader>
            <Trans>
              Benefits of accepting PayPal payments online, at your brick-and-mortar store, in your
              restaurant, or on the go with MONEI
            </Trans>
          </SectionHeader>
          <CardsContainer style={{marginTop: '60px'}}>
            <Card>
              <CardImage src={site_template} alt="" style={{top: -55, width: '100px'}} />
              <CardWrapper>
                <Trans>
                  <AnchorLink href="https://docs.monei.com/docs/payment-methods/paypal/">
                    Connect
                  </AnchorLink>{' '}
                  with your e-commerce store at no extra cost
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={coinstack} alt="" style={{top: -47, width: '100px'}} />
              <CardWrapper>
                <Trans parent="p">Boost customer satisfaction and sales</Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={cart} alt="" style={{width: '90px', top: -65}} />
              <CardWrapper>
                <Trans parent="p">
                  Decrease abandoned carts with a streamlined online payment flow
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={click} alt="" style={{width: '100px', top: -40}} />
              <CardWrapper>
                <Trans>Build trust and brand awareness with a customized payment page</Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={mobile_payment} alt="" style={{width: '130px', top: -50}} />
              <CardWrapper>
                <Trans parent="p">
                  Save money by{' '}
                  <InternalPageLink slug="monei-pay">
                    taking payments from your phone
                  </InternalPageLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={person} alt="" style={{width: '100px', top: -65}} />
              <CardWrapper>
                <Trans parent="p">
                  Reduce customer queues with faster, contactless in-person payments
                </Trans>
              </CardWrapper>
            </Card>
          </CardsContainer>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Discover MONEI</Trans>
            </SignUpButton>
          </SectionActions>
        </Section>
      </Content>
      <Background>
        <Content>
          <Section reverseOnMobile centered>
            <SectionImage
              src={moneiPayForm}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              height={650}
              className="hide-on-mobile"
            />
            <div>
              <SectionHeader>
                <Trans>How does PayPal work for customers?</Trans>
              </SectionHeader>
              <Trans parent="p">
                The payment process is simple and easy. When your customers choose PayPal payments,
                they can log in to their PayPal account and instantly complete the purchase with
                their stored payment details. And because MONEI Pay is a web-based payment app, you
                can also use it to accept PayPal in person.
              </Trans>
              <Trans parent="p">
                <Bold>How PayPal online payments work:</Bold>
              </Trans>
              <List>
                <Trans parent="li">Customer goes to checkout and selects the PayPal button</Trans>
                <Trans parent="li">
                  Customer is redirected to PayPal to log in and complete the transaction
                </Trans>
                <Trans parent="li">
                  Customer selects a stored payment method or enters a new one
                </Trans>
                <Trans parent="li">
                  Customer authorizes payment and is redirected back to your website
                </Trans>
              </List>

              <Trans parent="p">
                <Bold>How PayPal in-person payments work:</Bold>
              </Trans>
              <List>
                <Trans parent="li">
                  You create a payment in the{' '}
                  <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink> app
                </Trans>
                <Trans parent="li">
                  Customer scans the QR code or you send a payment request to their mobile phone
                </Trans>
                <Trans parent="li">
                  Customer selects the PayPal button on the payment page and is redirected to PayPal
                </Trans>
                <Trans parent="li">
                  Customer selects a stored payment method or enters a new one
                </Trans>
                <Trans parent="li">Customer authorizes the payment</Trans>
              </List>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content>
        <Section centered column style={{maxWidth: '1024px', margin: 'auto', textAlign: 'center'}}>
          <SectionHeader underline sx={{margin: '0'}}>
            Take PayPal payments at your business with MONEI
          </SectionHeader>
          <Trans parent="p">
            Whether you have an{' '}
            <PrismicPage slug="payment-method-integrations-ecommerce-platforms">
              online shop
            </PrismicPage>
            , multiple{' '}
            <InternalPageLink slug="monei-pay/retail-POS-system">
              brick-and-mortar locations
            </InternalPageLink>{' '}
            , a{' '}
            <InternalPageLink slug="monei-pay/food-truck-pos-system">
              restaurant/food truck
            </InternalPageLink>{' '}
            , or need to collect PayPal payments on the go for{' '}
            <InternalPageLink slug="monei-pay/pos-for-freelancers">your services</InternalPageLink>{' '}
            , MONEI is here to help.
          </Trans>
          <br />
          <Trans parent="p">
            <Bold>Accept PayPal online</Bold>
          </Trans>
          <Trans parent="p">
            Use a simple plugin to{' '}
            <AnchorLink href="https://docs.monei.com/docs/payment-methods/paypal/">
              add PayPal
            </AnchorLink>{' '}
            to all major{' '}
            <PrismicPage slug="payment-method-integrations-ecommerce-platforms">
              e-commerce platforms
            </PrismicPage>
            , or integrate with your custom-built website using our{' '}
            <AnchorLink href="https://docs.monei.com/api/#tag/Payments">Payments API</AnchorLink>.
          </Trans>
          <br />
          <Trans parent="p">
            <Bold>Accept PayPal in person</Bold>
          </Trans>
          <Trans parent="p">
            Take PayPal payments from your phone and view online and in-person transaction history
            from anywhere. Download the{' '}
            <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink> app (
            <AndroidDownloadLink>Android</AndroidDownloadLink> or{' '}
            <IosDownloadLink>iOS</IosDownloadLink>).
          </Trans>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
        </Section>
        <Partners />
      </Content>
      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Paypal FAQs</Trans>} />
        </Section>
      </Content>
      {popularPosts.length > 0 && (
        <>
          <Content>
            <Section sx={{paddingBottom: '0px'}}>
              <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <SectionHeader underline sx={{textAlign: 'center'}}>
                  <Trans>Latest news about PayPal payments</Trans>
                </SectionHeader>
              </div>
            </Section>
          </Content>
          <RelatedBlogPosts posts={popularPosts} />
        </>
      )}
    </>
  );
};

export default Paypal;

export const query = graphql`
  query ($language: String!, $prismicLang: String) {
    locales: allLocale(filter: {ns: {in: ["common", "paypal"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismic: prismic {
      allCategorys(lang: $prismicLang) {
        edges {
          node {
            title
            _meta {
              uid
              lang
            }
            order
          }
        }
      }
    }
    popularPosts: prismic {
      allPosts(
        sortBy: meta_lastPublicationDate_DESC
        tags: "PayPal"
        lang: $prismicLang
        first: 4
      ) {
        edges {
          node {
            title
            image
            summary
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`;
